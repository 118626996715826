import { Container, Row, Col, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import NinetyDegrees from '../img/office-optimised.jpg';
import Angles from '../img/angles-optimised.jpg';
import CardOne from '../img/card1.jpg';
import CardTwo from '../img/card2.jpg';
import CardThree from '../img/card3.jpg';
import Footer from '../components/Footer/Footer';

const ClientsPage = () => {

    const homepageBackground = {
        backgroundImage: `url(${NinetyDegrees})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh'
    }

    const card = {
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)',
        color: '#fff',
        marginBottom: '25px'
    }

    const cardText = {
        padding: '30px'
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px'
    }

    return (
        <div style={homepageBackground}>
        <Container fluid>
            <Row style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                <NavigationWhite />
                <Col>
                    <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>Clients</h1>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                <Col sm={{ span: 6, offset: 3 }}>
                    <div>
                        <p>Using a recruitment agency such as OpenTech Partners gives you access to candidates that may normally be out of reach through internal methods of onboarding staff. Furthermore, utilising the industry knowledge of recruitment professionals opens the door for more candidates that align with your needs to be spotted, meaning long-term and successful hires. Along with our industry knowledge and access we give our absolute all to our clients, you will get commitment from day one, to the day they start and thereafter.</p>
                    </div>
                </Col>
            </Row>

            <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                <Col sm={{ span: 5, offset: 1 }}>
                    <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={Angles} alt="High rise buildings" style={{ width: '100%' }} />
                    </div>

                </Col>
                <Col sm={5}>
                    <div style={{ height: '400px', display: 'flex', alignItems: 'center', alignText: 'center', padding: '50px' }}>
                        <p>OpenTech take the time to build sustainable partnerships by delving into the requirements, understanding short-, medium- and long-term goals. From there, we will effectively and efficiently source the correct talent for the aforementioned needs. With open and honest communication, we will work as an extension of your talent sourcing team to work alongside you in your organizational growth.</p>
                    </div>

                </Col>
            </Row>

            <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardOne} alt="Meeting room" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>View roles</h3>
                                    <p>Some call to action text</p>
                                    <Button style={buttonStyle}>View roles</Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardTwo} alt="Two women writing on a whiteboard" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Join us</h3>
                                    <p>Some call to action text</p>
                                    <Button style={buttonStyle}>Join us</Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardThree} alt="An office building" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Clients</h3>
                                    <p>Some call to action text</p>
                                    <Button style={buttonStyle}>How we work</Button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Col>

           <Footer />

        </Container>
    </div>

    );
}

export default ClientsPage;