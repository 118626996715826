import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Row, Col, Button } from 'react-bootstrap';
import logo from '../../img/logo/White/Opentech_logo_long_white.png';

const NavigationWhite = () => {

    const brandStyle = {
        height: '70px'
    }

    const navItemStyle = {
        color: '#fff',
        marginRight: '30px',
        textDecoration: 'none',
        textTransform: 'uppercase'
    }

    return (
        <Navbar expand="lg" style={{ height: '100px' }}>
            <Container fluid>
                <Row style={{ width: '100%' }}>
                    <Col sm={2}>
                        <Navbar.Brand href="#home" style={{ color: '#fff' }}><img src={logo} alt="OpenTech Partners logo" style={brandStyle} /></Navbar.Brand>
                    </Col>
                    <Col sm={{ span: 7, offset: 3 }} style={{ display: 'flex', alignItems: 'center' }}>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="m-auto">
                                <Nav.Link>
                                    <Link to="/" style={navItemStyle}>Home</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link to="/about" style={navItemStyle}>About OTP</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link to="/candidates" style={navItemStyle}>Open positions</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link to="/clients" style={navItemStyle}>Clients</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link to="/contact" style={navItemStyle}>Contact</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link to="/ecologi" style={navItemStyle}>Ecologi</Link>
                                </Nav.Link>
                                <Nav.Link style={{ display: 'none' }}>
                                    <Link to="/login" style={navItemStyle}>Log in</Link>
                                </Nav.Link>
                                <Nav.Link style={{ display: 'none' }}>
                                    <Link to="/admin" style={navItemStyle}>Admin</Link>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Col>
                </Row>
            </Container>
        </Navbar >
    );
}

export default NavigationWhite;