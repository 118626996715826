import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import DeansgateSquare from '../img/deansgate-square-optimised.jpg';
import ContactImage from '../img/contact.jpg';
import Footer from '../components/Footer/Footer';

const ContactPage = () => {

    const homepageBackground = {
        backgroundImage: `url(${ContactImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        height: '60vh'
    }

    const buttonStyleReverse = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px',
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)'
    }

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ backgroundColor: 'rgba(0,0,0,0.3' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>Contact</h1>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </Col>
                </Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                        <Col sm={12} md={6}>
                            <Form>
                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="Name" type="text" />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control placeholder="Email address" type="email" />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control placeholder="Contact number" type="tel" />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows="6" placeholder="Message" />
                                </Form.Group>

                                <Button type="submit" style={buttonStyleReverse}>
                                    Send message
                                </Button>
                            </Form>
                        </Col>
                        <Col sm={12} md={6}>
                            <img src={DeansgateSquare} alt="Buildings at Deansgate Square, Manchester" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Col>

                <Footer />

            </Container>
        </div>
    );
}

export default ContactPage;