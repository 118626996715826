import { Container, Row, Col, Alert } from 'react-bootstrap';
import Navigation from '../components/Navigation/Navigation';

const AdminPage = () => {


    return (
        <Container fluid>
            <Row>
                <Col>
                    <Navigation />
                    <h1>Admin</h1>
                    <Alert variant="danger">
                        <h2>This page won't be on the navbar when it's live. This is where that API will be hooked up, and you'll be directed to this page once you've logged in.</h2>
                    </Alert>
                    <ul>
                        <li>Add client</li>
                        <li>Add role (sits under client)</li>
                        <li>Edit client</li>
                        <li>Edit role</li>
                        <li>Delete client</li>
                        <li>Delete role</li>
                        <li>Add candidate</li>
                        <li>Edit candidate</li>
                        <li>Delete candidate</li>
                        <li>Export role</li>
                        <li>Send role to candidate</li>
                        <li>Recommend candidate to client</li>
                        <li>Client/Candidate sign up/login/dashboard etc?</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminPage;