import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../../img/logo/green-gradient/Opentech_logo_long_gradient.png';

const Navigation = () => {

    const brandStyle = {
        height: '70px'
    }

    const navItemStyle = {
        color: '#0b8752', 
        marginRight: '30px',
        textDecoration: 'none'
    }

    return (
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home" style={{ color: '#fff' }}><img src={logo} style={brandStyle} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link>
                            <Link to="/" style={navItemStyle}>Home</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/about" style={navItemStyle}>About</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/candidates" style={navItemStyle}>Candidates</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/clients" style={navItemStyle}>Clients</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/contact" style={navItemStyle}>Contact</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/sectors" style={navItemStyle}>Sectors</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/login" style={navItemStyle}>Log in</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link to="/admin" style={navItemStyle}>Admin</Link>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default Navigation;