import { Container, Row, Col, Form, InputGroup, Button, Alert } from 'react-bootstrap';
import Navigation from '../components/Navigation/Navigation';

const LoginPage = () => {


    return (
        <Container fluid>
            <Row>
                <Col>
                <Navigation />
                    <h1>Log in</h1>
                    <Alert variant="danger">
                        <h2>This page won't be on the navbar when it's live</h2>
                    </Alert>
                    <Row>
                <Col sm={12}>
                    <Col sm={{ span: 4, offset: 4 }}>
                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col>
                                    <Form.Label htmlFor="inlineFormInputGroup">
                                        Username
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col>
                                    <Form.Label htmlFor="inlineFormInputGroup">
                                        Password
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col>
                                    <Button type="submit" className="mb-2">
                                        Log in
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Col>
            </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;